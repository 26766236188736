export const GET_SESSION = "GET_SESSION";
export const GET_SESSION_MEMBERS = "GET_SESSION_MEMBERS";
export const NEW_MEMBER_ATTENDANCE = "NEW_MEMBER_ATTENDANCE";
export const SET_NEW_MEMBER_ATTENDACE = "SET_NEW_MEMBER_ATTENDACE";
export const REMOVE_MEMBER_ATTENDANCE = "REMOVE_MEMBER_ATTENDANCE";
export const REMOVE_TEAM_MEMBER = "REMOVE_TEAM_MEMBER";
export const REFUND_AMOUNT = "REFUND_AMOUNT";
export const GET_SESSION_INVOICES = "GET_SESSION_INVOICES";
export const SET_SESSION_INVOICES = "SET_SESSION_INVOICES";
export const SET_SESSION_MEMBERS = "SET_SESSION_MEMBERS";
export const SET_ERROR_MSG = "SET_ERROR_MSG";
export const CLEAR_ERROR = "CLEAR_ERROR";
